//Elementos de la pagina
const enlaces = document.querySelectorAll(".enlace");

//Esperando a que cargue el HTML
document.addEventListener("DOMContentLoaded",()=>{
    //Generando la galeria de imagenes
    const galeriaElemento = document.querySelector("#galeria");
    for(let i=1; i<=10;i++){
        const pictureElement = document.createElement("PICTURE");
        pictureElement.innerHTML = `
            <source srcset="build/img/Galeria/${i}.avif" type="image/avif">
            <source srcset="build/img/Galeria/${i}.webp" type="image/webp">
            <source srcset="build/img/Galeria/${i}.jpg" type="image/jpg">
            <img loading="lazy" src="build/img/Galeria/${i}.jpg" alt="Imagen de galeria">
        `;
        pictureElement.addEventListener("click", ()=>{
            agrandarImagen(i);
        })
        pictureElement.classList.add("galeria-imagen");
        
        //Asignando la foto a la galeria
        galeriaElemento.appendChild(pictureElement);
    }
});

function agrandarImagen(numero){
    
    const divPadre = document.createElement("DIV");
    divPadre.classList.add("layout");

    const imagenGrande = document.createElement("PICTURE");
    imagenGrande.innerHTML = `
        <source srcset="build/img/Galeria/${numero}.avif" type="image/avif">
        <source srcset="build/img/Galeria/${numero}.webp" type="image/webp">
        <source srcset="build/img/Galeria/${numero}.jpg" type="image/jpg">
        <img loading="lazy" src="build/img/Galeria/${numero}.jpg" alt="Imagen de galeria">
    `;

    divPadre.addEventListener("click", ()=>{
        divPadre.remove();
        document.body.classList.remove("fijar");
    });

    divPadre.appendChild(imagenGrande);
    document.body.appendChild(divPadre);
    document.body.classList.add("fijar");

}

//Agregando el evento a los enlaces
enlaces.forEach(element => {
    element.addEventListener("click", (event)=>{
        //Configurando el evento
        event.preventDefault();

        // Localiza el elemento al que referencia el ancla
        const elm = document.querySelector(element.getAttribute('href'));

        elm.scrollIntoView({block: "start", behavior: "smooth"});
    })
});